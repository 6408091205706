.swiper-pagination-bullet-active{
    background-color: #FFCD00;
}

.swiper-button-prev, .swiper-button-next{
    background-color: #FFF;
    height: 28px;
    width: 28px;
    border-radius: 100%;
    color: #000;
}

.swiper-button-next::after, .swiper-button-prev::after {
    font-size: 14px;
}