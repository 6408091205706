.root {
    /*background-color: #f4f6f8;*/
    display: flex;
    height: 100vh;
    overflow: hidden;
    width: 100vw;
    /*background: #F8FAFF;*/
}

.layout{
    width: 100%;
    height: 100%;
    position: relative;
}

.wrapper {
    display: flex;
    align-items: stretch;
    overflow: hidden;
    height: 100%;
}


.navbar{
    width: 240px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid #E5E5E5;
}

.content {
    height: 100%;
    flex-grow: 1;
    overflow: auto;
}